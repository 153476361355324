<template>
    <div class="mx-auto maxWidth">
        
    <!-- Header -->
    <TheHeader/>

    <!-- Banner -->
    <TheBanner hide-enquiry="true"/>

    <div class="container py-8 mx-auto text-center">
        <h1 class="pb-6 text-2xl poppin-bold md:text-3xl">Message Sending Successful!</h1>
        <p class="px-4 md:px-0 open-reg">
            Thank you for your kind interest in Hoe Soon Renovation & Plumbing Sanitary Work. We will get back to you as soon as possible.
        </p>
        <router-link to="/" class="block w-48 mx-auto">
            <p class="block py-2 my-5 text-yellow-300 bg-black rounded-lg poppin-semi">Back To Home</p>
        </router-link>
    </div>

    <!-- Copyright -->
    <Thecopyright/>

    </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheBanner from "@/components/TheBanner.vue";
import Thecopyright from "@/components/Thecopyright.vue";

export default {
    components: {
        TheHeader,
        TheBanner,
        Thecopyright,
    },
}
</script>

<style>

</style>